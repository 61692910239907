import React from "react";
import MenuItems from "./Menu";
import { Link, NavLink } from "react-router-dom";
import { Facebook, Instagram, Twitter } from "react-feather";
import MediaNav from "./MediaNav";
import Logo from "../images/logo.png";
import Home from "../components/Home";

export default function Nav() {
  return (
    <div className="px-4 md:px-12 lg:px-24 2xl:px-40 3xl:px-96 space-y-2 3xl:space-y-4 pt-6 bg-white relative">
      <div className="flex justify-between items-center">
        <div className="text-2xl 3xl:text-3xl tracking-widest font-bold hidden md:block font-stylescript text-gradient bg-gradient-to-r from-green-500 to-orange-500">
          <Link to="/">
            <img src={Logo} alt="logo" className=" w-1/2" />
          </Link>
        </div>
        <div className="md:hidden z-50">
          <MenuItems />
        </div>
        <div className="text-center w-full flex justify-center pl-12 sm:text-xl md:text-2xl 3xl:text-3xl tracking-widest font-bold">
          AIWANOSE ODAFEN
        </div>
        <div className="flex place-items-center space-x-3">
          <a
            href="https://m.facebook.com/aiwanose.odafen"
            target="_blank"
            className="rounded-full p-2 cursor-pointer"
          >
            <Facebook size={18} className="text-facebook" />
          </a>
          <a
            href="https://twitter.com/aiwahannah/"
            target="_blank"
            className="rounded-full p-2 cursor-pointer"
          >
            <Twitter size={18} className="text-twitter" />
          </a>
          <a
            href="https://www.instagram.com/aiwanoseodafen"
            target="_blank"
            className="rounded-full p-2 cursor-pointer"
          >
            <Instagram size={18} className="text-instagram" />
          </a>
        </div>
      </div>
      <div className="hidden md:block">
        <nav className="flex justify-center text-xs 3xl:text-base">
          <ul className="flex space-x-2">
            <li className="text-gray-600 hover:text-black">
              <NavLink
                exact
                activeClassName="border-b-2 border-b p-1 border-gray-400"
                to="/"
              >
                HOME
              </NavLink>
            </li>
            <div>.</div>
            <li className="text-gray-600 hover:text-black">
              <NavLink
                exact
                activeClassName="border-b-2 border-b p-1 border-gray-400"
                to="/about"
              >
                ABOUT
              </NavLink>
            </li>
            <div>.</div>
            <li className="text-gray-600 hover:text-black">
              <NavLink
                exact
                activeClassName="border-b-2 border-b p-1 border-gray-400"
                to="/books"
              >
                BOOKS
              </NavLink>
            </li>
            <div>.</div>
            {/* <li className="text-gray-600 hover:text-black z-30 -mt-2">
              <MediaNav />
            </li>
            <div>.</div> */}
            <li className="text-gray-600 hover:text-black">
              <NavLink
                exact
                activeClassName="border-b-2 border-b p-1 border-gray-400"
                to="/newsevents"
              >
                NEWS-EVENTS
              </NavLink>
            </li>
            <div>.</div>
            <li className="text-gray-600 hover:text-black">
              <NavLink
                exact
                activeClassName="border-b-2 border-b p-1 border-gray-400"
                to="/mailinglist"
              >
                MAILING LIST
              </NavLink>
            </li>
            <div>.</div>
            <li className="text-gray-600 hover:text-black">
              <NavLink
                exact
                activeClassName="border-b-2 border-b p-1 border-gray-400"
                to="/contact"
              >
                CONTACT
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
