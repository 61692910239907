import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../helper/Footer";
import Nav from "../../helper/Nav";
import Buy from "./Buy";

import { ContentfulContext } from "../../context/contentfulContext";
import moment from "moment";

import { ReactComponent as Amazon } from "../../components/book/amazon.svg";
import { ReactComponent as BlackWells } from "../../components/book/blackwells.svg";
import { ReactComponent as BookSellers } from "../../components/book/booksellers.svg";
import { ReactComponent as BookShop } from "../../components/book/bookshop.svg";
import { ReactComponent as Hive } from "../../components/book/hive.svg";
import { ReactComponent as Waterstones } from "../../components/book/waterstones.svg";
import { ReactComponent as WHSmith } from "../../components/book/whsmith.svg";
import { ReactComponent as Wordery } from "../../components/book/wordery.svg";

export default function Book() {
  const { book } = useParams();
  const { allPages } = useContext(ContentfulContext);
  const booksPage = allPages?.booksPage;

  const [filteredBook, setFilteredBook] = useState({});
  const [storesDetails, setStoresDetails] = useState([]);

  const stores = [
    {
      name: "Amazon",
      logo: <Amazon />,
    },
    {
      name: "BlackWells",
      logo: <BlackWells />,
    },
    {
      name: "BookSellers",
      logo: <BookSellers />,
    },
    {
      name: "BookShop",
      logo: <BookShop />,
    },
    { name: "Hive", logo: <Hive /> },
    { name: "Waterstones", logo: <Waterstones /> },
    { name: "WHSmith", logo: <WHSmith /> },
    { name: "Wordery", logo: <Wordery /> },
  ];

  const getStoreImages = (index) => {
    if (booksPage) {
      const storeLinks = booksPage[index].stores;
      const modifiedUrls = storeLinks.map((url) => {
        const parsedUrl = new URL(url);
        const hostnameParts = parsedUrl.hostname.split(".");
        let domainName = "";

        if (hostnameParts.length === 2) {
          domainName = hostnameParts[0];
        } else if (hostnameParts.length > 2) {
          domainName = hostnameParts[1];
        }

        return domainName.replace("www", "");
      });

      const findImages = modifiedUrls.map((name, index) => {
        const findMe = stores.find((store) =>
          store.name.toLowerCase().includes(name)
        );
        return { ...findMe, link: storeLinks[index] };
      });
      setStoresDetails(findImages);
    }
  };

  useEffect(() => {
    if (booksPage) {
      const getBook = booksPage.find(
        (b) => b.title.toLowerCase().split(" ").join("-") === book
      );
      const bookIndex = booksPage.findIndex(
        (b) => b.title.toLowerCase().split(" ").join("-") === book
      );
      getStoreImages(bookIndex);

      setFilteredBook(getBook);
    }
  }, [booksPage]);

  return (
    <div className="space-y-16">
      <Nav />
      <section className="grid gap-8 px-4 md:px-12 lg:px-24 2xl:px-40 3xl:px-72 sm:grid-cols-2 md:grid-cols-3 sm:grid-rows-3 md:grid-rows-1">
        <div className="row-start-2 space-y-12 sm:row-start-1 md:pt-16">
          <div className="text-xl font-bold text-center md:text-2xl 3xl:text-3xl">
            {filteredBook?.title}
          </div>
          <div className="space-y-8 text-sm leading-7 3xl:text-base 3xl:leading-9">
            {filteredBook?.heroIntro}
          </div>
        </div>
        <div className="row-start-1">
          <img
            src={filteredBook?.coverImage}
            alt="fantastic beasts and where to find them"
          />
        </div>
        <div className="space-y-10 md:pt-16 md:row-start-1 sm:col-span-2">
          <div>
            <Buy data={storesDetails} />
          </div>
          <div className="w-full text-sm text-center">
            <blockquote className="space-y-6">
              <q className="line-clamp-2">{filteredBook?.heroQuote}</q>
            </blockquote>
          </div>
          <div className="text-xs text-center text-gray-600">
            <div>PUBLISHED: {moment(filteredBook?.publishedDate).year()}</div>
            <div>PAGES: {filteredBook?.pages}</div>
          </div>
        </div>
        <div className="row-start-3 space-y-4 sm:row-start-2 sm:col-span-2 md:col-span-3">
          <div className="hidden text-lg italic font-bold 3xl:text-3xl md:block">
            ( cont... )
          </div>
          <div className="w-3/4 space-y-8 text-sm leading-7 3xl:text-base 3xl:leading-9">
            {filteredBook?.introCont}
          </div>
        </div>
      </section>

      {/* <section className="grid grid-cols-4 gap-3 px-4 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-7 md:px-12 lg:px-24 2xl:px-40 3xl:px-72">
        {filteredBook?.bookCountryCover?.map((country) => (
          <a href="#" className="space-y-2">
            <img src={country.src} alt={country.country} />
            <div className="text-center 3xl:text-lg">{country.country}</div>
          </a>
        ))}
      </section> */}

      {filteredBook?.praisedFor?.length > 0 && (
        <section className="px-4 space-y-4 md:px-12 lg:px-24 2xl:px-40 3xl:px-72 sm:space-y-4">
          <div className="sm:text-lg">Praise for: </div>
          <div className="text-lg font-extrabold sm:text-2xl">
            {filteredBook?.title}
          </div>
          <div className="w-full">
            {/* {filteredBook?.praiseFor?.map((praise) => ( */}
            <div className="space-y-2">
              <div className="grid text-sm leading-7 sm:grid-cols-2 gap-x-4 gap-y-8">
                {filteredBook?.praisedFor}
              </div>
            </div>
            {/* ))} */}
          </div>
        </section>
      )}
      <Footer />
    </div>
  );
}
