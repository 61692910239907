import moment from "moment";
import React, { useContext } from "react";
import { ContentfulContext } from "../../context/contentfulContext";
import Footer from "../../helper/Footer";
import Nav from "../../helper/Nav";

const newsevents = [
  {
    news: [
      {
        id: 1,
        day: 28,
        month: "Sept.",
        year: 2020,
        news: [
          "Simon & Schuster imprint Scribner is to publish debut writer Aiwanose Odafen's feminist novel Tomorrow I Become a Woman as part of a three-book deal. ",
          'Commenting on the novel, Shang said: "Tomorrow I Become a Woman has everything I hope to find in a book and I feel honoured that this is my first acquisition. Aiwa’s debut is a feminist novel, but with a lightness of touch that educates the reader and is all the more powerful for it. Equally, the political backdrop provides a fascinating insight into recent Nigerian history and made me want to learn more.',
        ],
        heading: "Scribner scoops Odafen's 'powerful' debut novel",
        link: "https://www.thebookseller.com/news/scribner-scoops-odafens-powerful-debut-novel-1220249",
      },
      {
        id: 2,
        day: 21,
        month: "Aug.",
        year: 2021,
        news: [
          "Scribner U.K is excited to reveal the cover for Aiwanose Odafen's debut novel",
        ],
        heading: "Book Cover Reveal",
        link: "https://www.simonandschuster.co.uk/books/Tomorrow-I-Become-a-Woman/Aiwanose-Odafen/9781398506114?ltclid=4ad46b65-21d2-483c-b19b-c74e2c044490",
      },
    ],
    events: [{}],
  },
];

export default function NewsEvents() {
  const { allPages } = useContext(ContentfulContext);
  const newsPage = allPages?.newsPage;
  console.log("News: ", newsPage);
  return (
    <div className="space-y-16">
      <Nav />
      <div className="grid px-4 divide-x-2 md:px-12 lg:px-24 2xl:px-40 3xl:px-96 sm:grid-cols-2 gap-y-8 divide-dashed">
        <div className="col-span-2 pr-4 space-y-12 text-sm 3xl:text-base bg-yellow-00">
          <div className="text-2xl text-gray-600 3xl:text-3xl">NEWS</div>
          {newsPage &&
            newsPage?.map((news) => (
              <div className="space-y-12">
                <div className="flex space-x-8">
                  <div>
                    <div className="border divide-y">
                      <div className="px-4 py-1 text-3xl font-bold text-white bg-gray-600 3xl:text-4xl">
                        {moment(news.date).format("DD")}
                      </div>
                      <div className="px-4 py-1 font-bold bg-gray-100 3xl:text-lg">
                        {moment(news.date).format("MMM")}
                      </div>
                      <div className="px-4 py-1 font-bold bg-gray-100 3xl:text-lg">
                        {moment(news.date).year()}
                      </div>
                    </div>
                  </div>
                  <div className="space-y-4 leading-7 3xl:leading-9">
                    <div className="text-lg font-bold 3xl:text-2xl">
                      {news.title}
                    </div>
                    <div>
                      <div className="space-y-3">
                        <p>{news.description}</p>
                      </div>
                      <div className="flex items-center justify-end space-x-1 hover:text-gray-600 3xl:text-lg">
                        <a
                          href={`${news.linkToArticle}`}
                          target="_blank"
                          className="underline hover:no-underline"
                        >
                          Read more...
                        </a>
                        <svg
                          className="w-3 h-3"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        {/* <div className="pl-4 space-y-6 text-sm bg-red-00">
          <div className="text-2xl text-gray-600">EVENTS</div>
        </div> */}
      </div>
      <Footer />
    </div>
  );
}
