import { createContext, useEffect, useState } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { client } from "../lib/client";

export const ContentfulContext = createContext();

export default function ContentfulProvider({ children }) {
  const [allPages, setAllPages] = useState({});
  const [loading, setLoading] = useState(false);

  const cleanUp = (rawData) => {
    const cleanData = rawData.map((data) => {
      const { fields } = data;
      return fields;
    });
    return cleanData;
  };
  const cleanUpLandingPage = (rawData) => {
    const fieldData = cleanUp(rawData);
    const cleanData = fieldData.map((field) => {
      const bookStores = field.bookStores;
      const heading = field.heading;
      const homeBio = field.homeBio;
      const outText = field.outText;
      const praiseForTitle = field.praiseForTitle;
      const quote = field.quote;
      const subheading = field.subheading;
      const heroBookImg = field.heroBookImg.fields.file.url;
      const homeBioImage = field.homeBioImage.fields.file.url;
      const heroImg = field.heroImg.fields.file.url;
      const heroQuote = documentToReactComponents(field.heroQuote);
      const praiseForImage = field.praiseForImage.fields.file.url;

      const praiseForContents = documentToReactComponents(
        field.praiseForContents
      );

      const cleanedData = {
        bookStores,
        heading,
        homeBio,
        outText,
        praiseForTitle,
        quote,
        subheading,
        heroBookImg,
        heroQuote,
        praiseForImage,
        praiseForContents,
        heroImg,
        homeBioImage,
      };
      return cleanedData;
    });

    return cleanData;
  };

  const cleanUpAboutPage = (data) => {
    const fieldData = cleanUp(data);

    const cleanData = fieldData.map((field) => {
      const heading = field.heading;
      const image = field.image.fields.file.url;
      const bio = documentToReactComponents(field.bio);
      const cleanedData = { heading, image, bio };
      return cleanedData;
    });
    return cleanData;
  };

  const cleanUpBooksPage = (data) => {
    const fieldData = cleanUp(data);

    const cleanData = fieldData.map((field) => {
      const title = field.title;
      const pages = field.pages;
      const publishedDate = field.publishedDate;
      const stores = field.stores;
      const coverImage = field.coverImage.fields.file.url;
      const heroIntro = documentToReactComponents(field.heroIntro);
      const heroQuote = documentToReactComponents(field.heroQuote);
      const praisedFor = documentToReactComponents(field.praisedFor);
      const introCont = documentToReactComponents(field.introCont);

      const cleanedData = {
        title,
        pages,
        publishedDate,
        coverImage,
        heroIntro,
        heroQuote,
        introCont,
        praisedFor,
        stores,
      };
      return cleanedData;
    });

    return cleanData;
  };

  const cleanUpNewsPage = (data) => {
    const fieldData = cleanUp(data);

    const cleanData = fieldData.map((field) => {
      const title = field.title;
      const date = field.date;
      const linkToArticle = field.linkToArticle;
      const description = documentToReactComponents(field.description);
      const cleanedData = { title, date, description, linkToArticle };
      return cleanedData;
    });

    return cleanData;
  };

  const getAllPages = async () => {
    setLoading(true);
    try {
      const landingPageResponse = await client.getEntries({
        content_type: "landingPage",
      });
      const aboutResponse = await client.getEntries({ content_type: "about" });
      const booksResponse = await client.getEntries({ content_type: "book1" });
      const newsResponse = await client.getEntries({ content_type: "news" });
      const landingPagefilteredData = cleanUpLandingPage(
        landingPageResponse.items
      );

      const aboutFilteredData = cleanUpAboutPage(aboutResponse.items);
      const booksFilteredData = cleanUpBooksPage(booksResponse.items);
      const newsFilteredData = cleanUpNewsPage(newsResponse.items);

      setAllPages({
        landingPage: landingPagefilteredData,
        aboutPage: aboutFilteredData,
        booksPage: booksFilteredData,
        newsPage: newsFilteredData,
      });
      setLoading(false);
    } catch (error) {
      console.log("An error occurred ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllPages();
  }, []);
  return (
    <ContentfulContext.Provider value={{ allPages, loading }}>
      {children}
    </ContentfulContext.Provider>
  );
}
