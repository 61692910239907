import React, { useState } from "react";
import Footer from "../helper/Footer";
import Nav from "../helper/Nav";
import { send } from "emailjs-com";

export default function Contact() {
  const [toSend, setToSend] = useState({
    from_name: "",
    to_name: "Aiwa",
    message: "",
    reply_to: "",
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  const onSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    send(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      toSend,
      process.env.REACT_APP_USER_ID
    )
      .then(() => {
        setMessage({
          type: "success",
          message:
            "Your message has been received! We'll get back to you shortly.",
        });
      })
      .catch((err) => {
        console.log("FAILED...", err);
        setMessage({
          type: "error",
          message: "An error occurred! Please try again.",
        });
      });
    setLoading(false);
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div className="space-y-16">
      <Nav />
      <div
        id="contact"
        className="px-4 text-2xl text-gray-600 md:px-12 lg:px-24 2xl:px-40 3xl:px-96 3xl:text-3xl"
      >
        CONTACT
      </div>
      <div className="grid gap-8 px-4 md:px-12 lg:px-24 2xl:px-40 3xl:px-96 md:grid-cols-2">
        <div className="space-y-8 leading-7 3xl:leading-9 3xl:text-lg">
          <div className="text-2xl italic font-bold 3xl:text-3xl">
            Literary Agent:
          </div>
          <div>For rights, permissions and other inquiries:</div>
          <div className="space-y-6 font-extralight">
            <div className="space-y-2">
              <div className="font-bold">Books</div>
              <div>
                <div>Cathryn Summerhayes</div>
                <div>SummerhayesOffice@curtisbrown.co.uk</div>
                <div>Tel: +44 (0)207 393 4281</div>
              </div>
            </div>
            <div className="space-y-2">
              <div className="font-bold">Translation Rights</div>
              <div>
                <div>Katie McGowan</div>
                <div>katie.mcgowan@curtisbrown.co.uk</div>
                <div>Tel: +44 (0)20 7393 4425</div>
              </div>
            </div>

            <div className="space-y-2">
              <div className="font-bold">Other inquiries</div>
              <div>
                <div>Aiwanose Odafen (Me)</div>
                <div>hello@aiwanose.com</div>
                {/* <div>Tel: +44 (0)20 7393 4425</div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-12">
          <div className="text-2xl italic font-bold 3xl:text-3xl">
            Speaking & Other Engagements
          </div>
          <form
            className="grid grid-cols-2 gap-4 text-sm 3xl:text-base"
            onSubmit={onSubmit}
          >
            <div className="col-span-2">
              <label htmlFor="from_name" className="sr-only">
                First name
              </label>
              <input
                className="w-full px-3 py-2 font-medium text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md shadow-sm"
                type="text"
                id="from_name"
                name="from_name"
                required
                placeholder="Full Name"
                onChange={handleChange}
              />
            </div>

            <div className="col-span-2">
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <input
                className="w-full px-3 py-2 font-medium text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md shadow-sm"
                type="email"
                id="reply_to"
                name="reply_to"
                autoComplete="email"
                required
                placeholder="Email address"
                onChange={handleChange}
              />
            </div>
            <div className="col-span-2">
              <label htmlFor="message" className="sr-only">
                Type message
              </label>
              <textarea
                className="w-full px-3 py-2 font-medium text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md shadow-sm"
                name="message"
                id="message"
                cols="30"
                rows="10"
                required
                placeholder="Type your message here..."
                onChange={handleChange}
              ></textarea>
            </div>
            {message && (
              <div className="col-span-2">
                <span
                  style={{ color: message?.type === "error" ? "red" : "green" }}
                >
                  {message?.message}
                </span>
              </div>
            )}
            <div className="col-span-2 text-right">
              <button
                className="px-4 py-2 font-medium text-white bg-gray-600 border rounded-md shadow-sm hover:bg-gray-700"
                disabled={loading}
              >
                {loading ? "Please wait..." : "Send"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}
