import React, { useContext } from "react";
import { Route, Switch } from "react-router";
import About from "./components/About";
import Book from "./components/book/Book";
import Books from "./components/Books";
import Contact from "./components/Contact";
import Home from "./components/Home";
import MailingList from "./components/MailingList";
import NewsEvents from "./components/media/NewsEvents";
// import InterviewsMentions from "./components/media/InterviewsMentions";
import { ContentfulContext } from "./context/contentfulContext";

export default function App() {
  const { loading } = useContext(ContentfulContext);
  return (
    <div className="text-gray-800 font-lora">
      {loading ? (
        <div className="flex items-center justify-center w-full h-screen">
          <img src="/loading.gif" alt="loading" width={300} />
        </div>
      ) : (
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/books" component={Books} />
          <Route exact path="/books/:book" component={Book} />
          <Route exact path="/newsevents" component={NewsEvents} />
          {/* <Route
            exact
            path="/interviewsmentions"
            component={InterviewsMentions}
          /> */}
          <Route exact path="/mailinglist" component={MailingList} />
          <Route exact path="/contact" component={Contact} />
        </Switch>
      )}
    </div>
  );
}
