import React from "react";
import { Link } from "react-router-dom";
import MailingList from "../components/MailingList";
import Logo from "../images/logo.png";

export default function Footer() {
  return (
    <footer className="px-4 md:px-12 lg:px-24 2xl:px-40 3xl:px-96 text-xs sm:flex justify-between items-center leading-tight pb-6 space-y-2">
      <div className="flex space-x-4 justify-center">
        <Link
          to={{
            pathname: "/mailinglist",
            hash: "#mailinglist",
            state: { MailingList: true },
          }}
          className="text-gray-600 hover:text-black"
        >
          MAILING LIST
        </Link>
        <Link to="/contact" className="text-gray-600 hover:text-black">
          CONTACT
        </Link>
      </div>
      <div className="flex justify-center items-center space-x-4">
        <Link to="#" className="text-gray-600 hover:text-black">
          PRIVACY
        </Link>
        <div className="flex justify-center items-center space-x-1">
          <a href="#" className="text-gray-600 text-center hover:text-black">
            ©
          </a>
          <div className="text-2xl tracking-widest font-bold hidden md:block ">
            {/* <span className="font-stylescript text-gradient bg-gradient-to-r from-green-500 to-orange-500">
              Aiwa.O
            </span> */}
            <Link to="/">
              <img src={Logo} alt="logo" className=" w-14" />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
