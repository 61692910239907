import React, { useContext } from "react";
import { ContentfulContext } from "../context/contentfulContext";
import Footer from "../helper/Footer";
import Nav from "../helper/Nav";

export default function About() {
  const { allPages } = useContext(ContentfulContext);
  const aboutPage = allPages?.aboutPage?.length > 0 && allPages?.aboutPage[0];
  return (
    <div className="space-y-16">
      <Nav />
      <div className="px-4 text-2xl text-gray-600 md:px-12 lg:px-24 2xl:px-40 3xl:px-96">
        ABOUT
      </div>
      <section className="grid px-4 text-sm md:grid-cols-2 lg:grid-cols-5 3xl:grid-cols-6 gap-y-8 sm:gap-8 md:px-12 lg:px-24 2xl:px-40 3xl:px-96 3xl:text-base">
        <div className="lg:col-span-2">
          <img
            src={aboutPage.image}
            alt="aiwanose"
            className="object-cover sm:w-96 sm:h-96"
          />
        </div>
        <div className="space-y-6 lg:col-span-3 3xl:col-span-4">
          <div className="-mt-2 text-xl font-bold text-center md:text-2xl 3xl:text-3xl">
            {aboutPage.heading}
          </div>
          <div className="space-y-2 leading-7 3xl:leading-9">
            {aboutPage.bio}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
