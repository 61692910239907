import React, { useContext, useEffect, useState } from "react";
import home from "../images/home.jpg";
import Nav from "../helper/Nav";
import book from "../images/book.jpeg";
import parallax from "../images/parallax.jpeg";
import aboutImg from "../images/about-home.jpg";
import { Link } from "react-router-dom";
import Buy from "./book/Buy";
import Footer from "../helper/Footer";
import { ContentfulContext } from "../context/contentfulContext";

import { ReactComponent as Amazon } from "../components/book/amazon.svg";
import { ReactComponent as BlackWells } from "../components/book/blackwells.svg";
import { ReactComponent as BookSellers } from "../components/book/booksellers.svg";
import { ReactComponent as BookShop } from "../components/book/bookshop.svg";
import { ReactComponent as Hive } from "../components/book/hive.svg";
import { ReactComponent as Waterstones } from "../components/book/waterstones.svg";
import { ReactComponent as WHSmith } from "../components/book/whsmith.svg";
import { ReactComponent as Wordery } from "../components/book/wordery.svg";

function Home() {
  const { allPages } = useContext(ContentfulContext);
  const [storesDetails, setStoresDetails] = useState([]);
  const landingPage =
    allPages?.landingPage?.length > 0 && allPages?.landingPage[0];

  const stores = [
    {
      name: "Amazon",
      logo: <Amazon />,
    },
    {
      name: "BlackWells",
      logo: <BlackWells />,
    },
    {
      name: "BookSellers",
      logo: <BookSellers />,
    },
    {
      name: "BookShop",
      logo: <BookShop />,
    },
    { name: "Hive", logo: <Hive /> },
    { name: "Waterstones", logo: <Waterstones /> },
    { name: "WHSmith", logo: <WHSmith /> },
    { name: "Wordery", logo: <Wordery /> },
  ];

  const getStoreImages = () => {
    if (landingPage) {
      const storeLinks = landingPage.bookStores;
      const modifiedUrls = storeLinks.map((url) => {
        const parsedUrl = new URL(url);
        const hostnameParts = parsedUrl.hostname.split(".");
        let domainName = "";

        if (hostnameParts.length === 2) {
          domainName = hostnameParts[0];
        } else if (hostnameParts.length > 2) {
          domainName = hostnameParts[1];
        }

        return domainName.replace("www", "");
      });

      const findImages = modifiedUrls.map((name, index) => {
        const findMe = stores.find((store) =>
          store.name.toLowerCase().includes(name)
        );
        return { ...findMe, link: storeLinks[index] };
      });
      setStoresDetails(findImages);
    }
  };
  
  useEffect(() => {
    getStoreImages();
  }, [landingPage]);
  return (
    <div>
      <div className="space-y-32">
        <div className="-mb-16">
          <Nav />
        </div>
        <section className="grid px-4 space-y-4 md:px-12 lg:px-24 2xl:px-40 3xl:px-96 sm:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-12 lg:gap-8">
          <div className="space-y-10 overflow-hidden leading-7 sm:pt-24">
            <div className="space-y-3">
              <div className="text-lg font-bold 3xl:text-xl">
                {landingPage.heading}
              </div>
              <div className="text-sm 3xl:text-base">
                {landingPage.subheading}
              </div>
            </div>
          </div>
          <div className="z-0">
            <div className="relative">
              <div className="absolute px-3 py-2 text-xs text-white bg-black rounded-md border-1 sm:-ml-12 top-5">
                {landingPage.outText}
              </div>
              <img
                src={landingPage.heroImg}
                alt="aiwanose"
                className="z-0 object-cover w-full rounded-lg"
              />
              <img
                src={landingPage.heroBookImg}
                alt="recent release"
                className="absolute w-2/5 left-2/3 top-1/2"
              />
            </div>
          </div>
          <div className="space-y-10 sm:col-span-2 lg:col-span-1 md:pt-16">
            <div>
              <Buy data={storesDetails} />
            </div>
            <div className="w-4/6 mx-auto text-sm text-center 3xl:text-base">
              <blockquote className="space-y-6 3xl:leading-7">
                <q>{landingPage.heroQuote}</q>
                <footer></footer>
              </blockquote>
            </div>
          </div>
        </section>

        <section className="grid px-4 text-sm md:px-12 lg:px-24 2xl:px-40 3xl:px-96 sm:grid-cols-5 lg:grid-cols-6 3xl:text-base gap-y-12 sm:gap-12">
          <div className="col-span-2 xl:w-3/4">
            <img src={landingPage.praiseForImage} alt="recent release" />
          </div>
          <div className="space-y-6 sm:space-y-8 sm:col-span-3 lg:col-span-4">
            <div className="text-xl font-bold md:text-2xl 3xl:text-3xl">
              {landingPage.praiseForTitle}
            </div>
            <div className="space-y-4 leading-7 3xl:text-base 3xl:leading-9">
              {/* <div className="space-y-1">
                <p>
                  ‘Aiwanose Odafen has written a <strong>gripping story</strong>{" "}
                  that holds a mirror to society,{" "}
                  <strong>
                    reflecting the harsh realities of gender inequality in our
                    society
                  </strong>
                  . The book forces us to reckon with the pervasiveness of
                  violence against women and girls, which remains one of the
                  most significant human rights violations impacting millions
                  globally. Yet rather than perpetuate a victim’s narrative,{" "}
                  <strong>
                    Tomorrow I Became a Woman is a survivor’s story of
                    self-determination, reminding us all of the strength and
                    resilience of women and girls everywhere
                  </strong>
                  .’
                </p>
                <div>
                  - <strong>Elizabeth Nyamayaro</strong>, author of{" "}
                  <em>I am a Girl From Africa</em>, senior advisor to Under
                  Secretary-General and Executive Director for UN Women and the
                  head HeForShe Movement
                </div>
              </div>
              <div className="space-y-1">
                <p>
                  <strong>
                    ‘Searing and beautifully rendered, Aiwanose Odafen’s writing
                    acutely speaks to intimate experiences at the crux of race,
                    gender, class, culture, and tradition.’
                  </strong>
                </p>
                <div>
                  - <strong>Koa Beck</strong>, author of <em>White Feminism</em>
                </div>
              </div> */}
              {landingPage.praiseForContents}
            </div>
            <div>
              <Link to="/books/1" className="leading-relaxed">
                <button className="flex items-center justify-center px-4 py-2 space-x-1 font-medium text-white bg-gray-700 rounded-md shadow-sm hover:bg-gray-800">
                  <span>More about {landingPage.praiseForTitle}</span>
                  <svg
                    className="w-5 h-5 ml-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </button>
              </Link>
            </div>
          </div>
        </section>

        <section
          className="flex items-center justify-center h-screen max-h-s sm:max-h-m lg:max-h-lg 2xl:max-h-xl"
          style={{
            background: `linear-gradient(to right bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 50, 0.2)), url(${parallax})`,
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
            alt: "parallax background",
          }}
        >
          <div className="w-9/12 px-4 text-2xl italic leading-snug text-center text-white md:text-3xl 3xl:text-4xl md:px-12 lg:px-24">
            <q>{landingPage.quote}</q>
          </div>
        </section>

        <section className="grid gap-12 px-4 text-sm md:px-12 lg:px-24 2xl:px-40 3xl:px-96 sm:grid-cols-5 lg:grid-cols-6 3xl:text-base xl:gap-0">
          <div className="sm:col-span-2 xl:w-3/4">
            <img src={landingPage.homeBioImage} alt="aiwanose odafen" />
          </div>
          <div className="space-y-4 leading-7 2xl:space-y-8 3xl:leading-9 sm:col-span-3 lg:col-span-4">
            <p>{landingPage.homeBio}</p>
            <div>
              <Link to="/about" className="leading-relaxed">
                <button className="flex items-center justify-center px-4 py-2 space-x-1 font-medium text-white bg-gray-700 rounded-md shadow-sm hover:bg-gray-800">
                  <span>Full Bio</span>
                  <svg
                    className="w-5 h-5 ml-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </button>
              </Link>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
}

export default Home;
