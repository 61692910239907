import React from "react";
import { ReactComponent as Amazon } from "./amazon.svg";
import { ReactComponent as BlackWells } from "./blackwells.svg";
import { ReactComponent as BookSellers } from "./booksellers.svg";
import { ReactComponent as BookShop } from "./bookshop.svg";
import { ReactComponent as Hive } from "./hive.svg";
import { ReactComponent as Waterstones } from "./waterstones.svg";
import { ReactComponent as WHSmith } from "./whsmith.svg";
import { ReactComponent as Wordery } from "./wordery.svg";

export default function Buy({ data }) {
  return (
    <div className="space-y-8">
      <div className="text-lg font-bold text-center 3xl:text-xl">
        Order now in print, e-book and audio
      </div>
      {/* <ul className="grid grid-cols-4 gap-4">
        <li>
          <a
            href="https://www.amazon.co.uk/exec/obidos/ASIN/1398506117?tag=siscuk-21"
            target="_blank"
            className="flex items-center justify-center px-1 border rounded-md py-"
          >
            <Amazon />
          </a>
        </li>
        <li>
          <a
            href="https://blackwells.co.uk/bookshop/product/9781398506114"
            target="_blank"
            className="flex items-center justify-center px-1 border rounded-md py-"
          >
            <BlackWells />
          </a>
        </li>
        <li>
          <a
            href="#"
            target="_blank"
            className="flex items-center justify-center px-1 border rounded-md py-"
          >
            <BookSellers />
          </a>
        </li>
        <li>
          <a
            href="https://uk.bookshop.org/books/tomorrow-i-become-a-woman-9781398506114/9781398506114"
            target="_blank"
            className="flex items-center justify-center px-1 border rounded-md py-"
          >
            <BookShop />
          </a>
        </li>
        <li>
          <a
            href="#"
            target="_blank"
            className="flex items-center justify-center px-1 border rounded-md py-"
          >
            <Hive />
          </a>
        </li>
        <li>
          <a
            href="https://www.waterstones.com/book/9781398506114"
            target="_blank"
            className="flex items-center justify-center px-1 border rounded-md py-"
          >
            <Waterstones />
          </a>
        </li>
        <li>
          <a
            href="https://www.whsmith.co.uk/products/tomorrow-i-become-a-woman/aiwanose-odafen/hardback/9781398506114.html"
            target="_blank"
            className="flex items-center justify-center px-1 border rounded-md py-"
          >
            <WHSmith />
          </a>
        </li>

        <li>
          <a
            href="https://wordery.com/9781398506114?utm_source=Referral&utm_medium=publisher&utm_campaign=s-s"
            target="_blank"
            className="flex items-center justify-center px-1 border rounded-md py-"
          >
            <Wordery />
          </a>
        </li>
      </ul> */}

      <ul className="grid grid-cols-4 gap-4">
        {data &&
          data.map((d) => (
            <li>
              <a
                href={d.link}
                alt={d.name}
                target="_blank"
                className="flex items-center justify-center px-1 border rounded-md py-"
              >
                {d.logo}
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
}
