import React, { useContext } from "react";
import Footer from "../helper/Footer";
import Nav from "../helper/Nav";
import { ContentfulContext } from "../context/contentfulContext";
import moment from "moment";

export default function Books() {
  const { allPages } = useContext(ContentfulContext);
  const booksPage = allPages?.booksPage;
  return (
    <div className="space-y-16">
      <Nav />
      <div className="px-4 text-2xl text-gray-600 md:px-12 lg:px-24 2xl:px-40 3xl:px-96">
        BOOKS
      </div>
      <section className="grid grid-cols-2 gap-8 px-4 md:px-12 lg:px-24 2xl:px-40 3xl:px-96 md:grid-cols-3 lg:grid-cols-4 gap-x-6 md:gap-x-8 gap-y-12">
        {booksPage &&
          booksPage.map((book, index) => (
            <a
              href={`/books/${book.title.toLowerCase().split(" ").join("-")}`}
              className="flex flex-col space-y-2 place-items-center"
              key={index}
            >
              <div className="space-y-8">
                <div className="shadow-md">
                  <img
                    src={book.coverImage}
                    alt={book.title}
                    className="object-cover"
                  />
                </div>
                <div className="text-sm font-bold text-center 3xl:text-base">
                  {book.title}
                </div>
              </div>
              <div className="text-xs font-bold text-gray-600 3xl:text-sm">
                {moment(book.publishedDate).year()}
              </div>
            </a>
          ))}
      </section>
      <Footer />
    </div>
  );
}
