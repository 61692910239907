import React from "react";
import Footer from "../helper/Footer";
import Nav from "../helper/Nav";
import newsletter from "../images/newsletter.png";
import Subscribe from "./Subscribe";

export default function MailingList() {
  return (
    <div className="space-y-16">
      <Nav />
      <section
        id="mailinglist"
        className="px-4 space-y-10 md:px-12 lg:px-24 2xl:px-40 3xl:px-96"
      >
        <div className="p-12 space-y-16 border-4 border-gray-400 border-dashed">
          <div className="flex flex-col space-y-4 place-items-center">
            <img src={newsletter} alt="newsletter" />
            <div className="text-4xl text-center text-gray-600">
              Join Aiwanose's Newsletter!
            </div>
          </div>
          <p className="leading-7 text-center">
            Would you like to receive notices about Aiwanose's news and events?
            If so, enter your email below. You can also remove yourself from the
            list, and we promise that we’ll only use your name for our own
            mailings
          </p>
          {/* <form className="grid gap-4 sm:grid-cols-3">
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none sm:col-span-2 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
              placeholder="Email address"
            />
            <button
              type="submit"
              className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md group hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Sign Up
            </button>
          </form> */}
          <Subscribe />
        </div>
      </section>
      <Footer />
    </div>
  );
}
