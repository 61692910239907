import React from "react";
import Mailchimp from "react-mailchimp-form";

export default function Subscribe() {
  console.log("Mailchimp Data: ", process.env.REACT_APP_MAILCHIMP_KEY);
  return (
    <Mailchimp
      action={process.env.REACT_APP_MAILCHIMP_KEY}
      fields={[
        {
          name: "EMAIL",
          placeholder: "Email",
          type: "email",
          required: true,
        },
      ]}
      messages={{
        sending: "Sending...",
        success: "Thank you for subscribing!",
        error: "An unexpected internal error has occurred.",
        empty: "You must write an e-mail.",
        duplicate: "Too many subscribe attempts for this email address",
        button: "Subscribe",
      }}
      style={{ backgroundColor: "red" }}
      className="mailchimp"
    />
  );
}
